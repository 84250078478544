import React, { useState, useEffect } from 'react';
import './FieldOfficer.css'; // Add your own CSS file for styling

const CACHE_KEY = 'field_officers_cache';
const CACHE_EXPIRATION = 4 * 60 * 1000; // 4 minutes in milliseconds

const FieldOfficer = () => {
  const [collectors, setCollectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCollector, setSelectedCollector] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loanRequests, setLoanRequests] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const fetchCollectors = async () => {
      const branchId = localStorage.getItem('srishti_branch_id');

      // Check cache
      const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
      const now = new Date().getTime();

      if (cachedData && now - cachedData.timestamp < CACHE_EXPIRATION) {
        setCollectors(cachedData.data);
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/branch/${branchId}/collectors`);
        if (!response.ok) {
          throw new Error('Failed to fetch collectors');
        }
        const data = await response.json();
        setCollectors(data);

        // Save to cache
        localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: now }));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCollectors();
  }, []);

  const handleShowPopup = async (collector) => {
    setSelectedCollector(collector);
    setShowPopup(true);
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan-requests/collector/${collector.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch loan requests');
      }
      const data = await response.json();
      setLoanRequests(data);

      // Calculate total amount
      const total = data.reduce((sum, loan) => sum + parseFloat(loan.amount || 0), 0);
      setTotalAmount(total);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedCollector(null);
    setLoanRequests([]);
    setTotalAmount(0);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="field-officer">
      <h1>Field Officers</h1>
      {collectors.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {collectors.map((collector) => (
              <tr key={collector.id}>
                <td>{collector.name}</td>
                <td>{collector.email}</td>
                <td>{collector.phone_no}</td>
                <td>{collector.address}</td>
                <td>
                  <button onClick={() => handleShowPopup(collector)}>Loans Assigned</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No collectors found.</p>
      )}

      {showPopup && selectedCollector && (
        <div className="popup">
          <div className="popup-content">
            <h2>Loans Assigned to {selectedCollector.name}</h2>
            {loanRequests.length > 0 ? (
              <>
                <p>Total Loans: {loanRequests.length}</p>
                <p>Total Amount: ₹{totalAmount.toFixed(2)}</p>
                <table>
                  <thead>
                    <tr>
                      <th>Loan Type</th>
                      <th>Customer Name</th>
                      <th>Interest</th>
                      <th>Amount</th>
                      <th>Approval Status</th>
                      <th>Posted At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loanRequests.map((loan) => (
                      <tr key={loan.id}>
                        <td>{loan.loan_type}</td>
                        <td>{loan.firstname} {loan.lastname}</td>
                        <td>{loan.interest_rate}%</td>
                        <td>{loan.amount}</td>
                        <td>{loan.approval_status}</td>
                        <td>{new Date(loan.posted_at).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No loans assigned under {selectedCollector.name}.</p>
            )}
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FieldOfficer;
