import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import './Navbar.css';

const CACHE_KEY = 'branch_name_cache';
const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

function Navbar({ toggleSidebar }) {
  const [branchName, setBranchName] = useState('');

  useEffect(() => {
    const fetchBranchData = async () => {
      const branchId = localStorage.getItem('srishti_branch_id');
      if (!branchId) {
        console.error('No branch ID found in local storage');
        return;
      }

      // Check for cached data
      const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
      const now = new Date().getTime();

      if (cachedData && now - cachedData.timestamp < CACHE_EXPIRATION) {
        setBranchName(cachedData.branchName);
        return;
      }

      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/branches/${branchId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch branch data');
        }
        const data = await response.json();

        setBranchName(data.branchname);

        // Save to cache
        localStorage.setItem(
          CACHE_KEY,
          JSON.stringify({ branchName: data.branchname, timestamp: now })
        );
      } catch (error) {
        console.error('Error fetching branch data:', error);
      }
    };

    fetchBranchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('srishti_branch_managerID');
    localStorage.removeItem('srishti_branch_id');
    localStorage.removeItem(CACHE_KEY); // Clear branch name cache on logout
    window.location.reload(); // Refresh the page after logout
  };

  return (
    <div className="navbar">
      <FaBars className="menu-icon" onClick={toggleSidebar} />
      <h1>Shristi Bank - Branch Manager ({branchName || 'Loading...'})</h1>
      <div className="user-info">
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Navbar;
