import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaWallet, FaPiggyBank, FaRegChartBar } from 'react-icons/fa';
import './Sidebar.css';

function Sidebar({ isSidebarOpen }) {
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <ul>
        <li>
          <Link to="/dashboard">
            <FaHome /><span>Dashboard</span>
          </Link>
        </li>
        
        <li>
          <Link to="/disbursement">
            <FaWallet /><span>Disbursement</span>
          </Link>

        </li>
      
        <li>
          <Link to="/loanrequests">
            <FaRegChartBar /><span>Loan Approvals</span>
          </Link>
        </li>

        <li>

          <Link to="/collections">
            <FaRegChartBar /><span>Collections</span>
          </Link>
        </li>

        <li>

          <Link to="/fieldofficers">
            <FaRegChartBar /><span>FieldOfficers</span>
          </Link>
        </li>

        <li>
          <Link to="/collection-reciept">
            <FaPiggyBank /><span>Collection Reciepts</span>
          </Link>
        </li>

       
      </ul>
    </div>
  );
}

export default Sidebar;
