import React, { useState, useEffect, useCallback } from 'react';
import './LoanRequests.css';

const LoanRequests = () => {
  const [loanRequests, setLoanRequests] = useState(() => {
    const saved = localStorage.getItem('loanRequestsData');
    return saved ? JSON.parse(saved) : [];
  });
  const [filteredLoanRequests, setFilteredLoanRequests] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [agentFilter, setAgentFilter] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLoanRequest, setCurrentLoanRequest] = useState(null);
  const [newCollector, setNewCollector] = useState('');
  const [newInterestRate, setNewInterestRate] = useState('');
  const [collections, setCollections] = useState([]);
  const [totalCollected, setTotalCollected] = useState(0);
  const [showCollections, setShowCollections] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageType, setImageType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const fetchUserDetails = async (userId) => {
    const savedUsers = JSON.parse(localStorage.getItem('userDetails') || '{}');
    if (savedUsers[userId]) {
      return savedUsers[userId];
    }

    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/admins/${userId}`);
      if (!response.ok) throw new Error(`Failed to fetch user details for ID ${userId}`);
      const userData = await response.json();
      const userDetails = { agentFirstname: userData.name };
      
      const updatedUsers = { ...savedUsers, [userId]: userDetails };
      localStorage.setItem('userDetails', JSON.stringify(updatedUsers));
      
      return userDetails;
    } catch (error) {
      console.error(error);
      return { agentFirstname: 'Unknown' };
    }
  };

  const fetchLoanRequests = useCallback(async () => {
    const lastFetch = localStorage.getItem('lastFetchTime');
    const savedData = localStorage.getItem('loanRequestsData');
    const now = new Date().getTime();
    
    if (lastFetch && savedData && (now - parseInt(lastFetch)) < 300000) {
      setLoanRequests(JSON.parse(savedData));
      return;
    }

    const branchId = localStorage.getItem('srishti_branch_id');
    if (!branchId) {
      setError('No branch ID found in local storage');
      return;
    }

    try {
      const response = await fetch('https://srishticooperativesociety.com/api/loan_requests');
      if (!response.ok) throw new Error('Failed to fetch loan requests');
      const data = await response.json();
      
      if (!Array.isArray(data)) {
        throw new Error('Invalid data format received');
      }

      const filteredData = data.filter(request => request.branch_id === parseInt(branchId, 10));
      const updatedData = await Promise.all(filteredData.map(async request => {
        const agentDetails = await fetchUserDetails(request.collector_assigned);
        return { ...request, agentFirstname: agentDetails.agentFirstname };
      }));

      localStorage.setItem('loanRequestsData', JSON.stringify(updatedData));
      localStorage.setItem('lastFetchTime', now.toString());
      
      setLoanRequests(updatedData);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching loan requests:', error);
    }
  }, []);

  const filterLoanRequests = useCallback((requests, query, agentName) => {
    if (!Array.isArray(requests)) return [];
    
    return requests.filter(request =>
      (request.id.toString().includes(query) ||
       request.firstname.toLowerCase().includes(query) ||
       request.lastname.toLowerCase().includes(query)) &&
      (request.agentFirstname.toLowerCase().includes(agentName) || agentName === '')
    );
  }, []);

  useEffect(() => {
    const savedData = localStorage.getItem('loanRequestsData');
    if (savedData) {
      setLoanRequests(JSON.parse(savedData));
    }
    fetchLoanRequests();
  }, [fetchLoanRequests]);

  useEffect(() => {
    try {
      const filtered = filterLoanRequests(loanRequests, searchQuery, agentFilter);
      setTotalPages(Math.ceil(filtered.length / itemsPerPage));
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setFilteredLoanRequests(filtered.slice(indexOfFirstItem, indexOfLastItem));
    } catch (error) {
      console.error('Error filtering loan requests:', error);
      setError('Error filtering loan requests');
    }
  }, [loanRequests, searchQuery, agentFilter, currentPage, itemsPerPage, filterLoanRequests]);

  const fetchImage = async (imageType, requestId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${requestId}`);
      if (!response.ok) throw new Error(`Failed to fetch ${imageType}`);
      const data = await response.json();
      const imageUrl = imageType === 'Aadhar' ? data.aadhar_card : data.profile_picture;
      setCurrentImage(`https://srishticooperativesociety.com/storage/app/` + imageUrl);
      setImageType(imageType);
      setIsImageModalOpen(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchCollections = async (loanId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/loan/${loanId}`);
      if (!response.ok) throw new Error(`Failed to fetch collections for loan ID ${loanId}`);
      const data = await response.json();

      const totalCollected = data
        .filter(collection => collection.status === 'accepted')
        .reduce((sum, collection) => sum + parseFloat(collection.collection_amount), 0);

      setCollections(data);
      setTotalCollected(totalCollected);
      setShowCollections(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const updateApprovalStatus = async (id, status) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ approval_status: status }),
      });

      if (!response.ok) throw new Error(`Failed to ${status} loan request`);
      await fetchLoanRequests();
    } catch (error) {
      setError(error.message);
    }
  };

  const updateLoanRequest = async (id, interestRate, collectorAssigned) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          interest_rate: interestRate, 
          collector_assigned: collectorAssigned 
        }),
      });

      if (!response.ok) throw new Error('Failed to update loan request');
      await fetchLoanRequests();
      setIsModalOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  const openModal = (loanRequest) => {
    setCurrentLoanRequest(loanRequest);
    setNewCollector(loanRequest.collector_assigned || '');
    setNewInterestRate(loanRequest.interest_rate.toString());
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentLoanRequest(null);
    setNewCollector('');
    setNewInterestRate('');
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setCurrentImage(null);
    setImageType('');
  };

  const handleInterestRateChange = (e) => {
    setNewInterestRate(e.target.value);
  };

  const handleSaveChanges = () => {
    if (currentLoanRequest) {
      updateLoanRequest(currentLoanRequest.id, parseFloat(newInterestRate), newCollector);
    }
  };

  return (
    <div className="loan-requests-page">
      <h2>Loan Requests</h2>
      {error && <p className="error-message">{error}</p>}
      
      <div className="search-filters">
        <input
          type="text"
          placeholder="Search by ID, Firstname, or Lastname"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
          className="search-input"
        />
        <input
          type="text"
          placeholder="Filter by Agent Name"
          value={agentFilter}
          onChange={(e) => setAgentFilter(e.target.value.toLowerCase())}
          className="agent-filter-input"
        />
      </div>
      
      {Array.isArray(filteredLoanRequests) && filteredLoanRequests.length > 0 ? (
        <>
          <table className="loan-requests-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Customer Name</th>
                <th>Address</th>
                <th>Interest Rate</th>
                <th>Duration</th>
                <th>Installment Type</th>
                <th>Amount</th>
                <th>View Aadhar</th>
                <th>View Photo</th>
                <th>Agent Name</th>
                <th>Status</th>
                <th>Amount (incl. Interest)</th>
                <th>Submitted at</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredLoanRequests.map(request => (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>{request.loan_type}</td>
                  <td>{request.firstname} {request.lastname}</td>
                  <td>{request.address}</td>
                  <td>{request.interest_rate}%</td>
                  <td>{request.duration} months</td>
                  <td>{request.installment_type}</td>
                  <td>₹{request.amount}</td>
                  <td>
                    <button onClick={() => fetchImage('Aadhar', request.id)}>View Aadhar</button>
                  </td>
                  <td>
                    <button onClick={() => fetchImage('Photo', request.id)}>View Photo</button>
                  </td>
                  <td>{request.agentFirstname}</td>
                  <td>{request.approval_status}</td>
                  <td>₹{((Number(request.amount)*(Number(request.interest_rate)*(Number(request.duration))/100)))+Number(request.amount)}</td>
                  <td>{formatDate(request.created_at)}</td>
                  <td>
                    <button onClick={() => openModal(request)}>Edit</button>
                    <button onClick={() => updateApprovalStatus(request.id, 'approved')}>Approve</button>
                    <button onClick={() => updateApprovalStatus(request.id, 'rejected')}>Reject</button>
                    <button onClick={() => fetchCollections(request.id)}>View Collections</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, i) => (
              <button
                key={i + 1}
                onClick={() => setCurrentPage(i + 1)}
                className={currentPage === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <p>No loan requests found.</p>
      )}

      {isModalOpen && currentLoanRequest && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h3>Edit Loan Request</h3>
            <div className="form-group">
              <label>Interest Rate:</label>
              <input
                type="number"
                value={newInterestRate}
                onChange={handleInterestRateChange}
              />
            </div>
            <button onClick={handleSaveChanges}>Save Changes</button>
          </div>
        </div>
      )}

      {isImageModalOpen && currentImage && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeImageModal}>&times;</span>
            <h3>{imageType} Image</h3>
            <img src={currentImage} alt={imageType} />
          </div>
        </div>
      )}

      {showCollections && collections.length > 0 && (
        <div className="collections-section">
          <h3>Collections for Loan ID {currentLoanRequest?.id}</h3>
          <table className="collections-table">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {collections.map(collection => (
                <tr key={collection.id}>
                  <td>{collection.collection_amount}</td>
                  <td>{collection.status}</td>
                  <td>{formatDate(collection.collection_date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>Total Collected: {totalCollected}</p>
        </div>
      )}
    </div>
  );
};

export default LoanRequests;