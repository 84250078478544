import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import Collection from '../collections/Collections';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [totalDisbursements, setTotalDisbursements] = useState(0);
  const [totalCollections, setTotalCollections] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalAgents, setTotalAgents] = useState(0); // New state for total agents

  useEffect(() => {
    const branchId = localStorage.getItem('srishti_branch_id');

    const fetchTotalDisbursements = async () => {
      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/total_disbursement/${branchId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch total disbursements');
        }
        const data = await response.json();
        setTotalDisbursements(parseFloat(data));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTotalCollections = async () => {
      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/total_collections/${branchId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch total collections');
        }
        const data = await response.json();
        setTotalCollections(parseFloat(data));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTotalMembers = async () => {
      try {
        const response = await fetch('https://srishticooperativesociety.com/api/users/total');
        if (!response.ok) {
          throw new Error('Failed to fetch total members');
        }
        const data = await response.json();
        setTotalMembers(parseInt(data.total_users, 10));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTotalAgents = async () => {
      try {
        const branchId = localStorage.getItem('srishti_branch_id');
        const response = await fetch(`https://srishticooperativesociety.com/api/branch/${branchId}/collectors`);
        if (!response.ok) {
          throw new Error('Failed to fetch total agents');
        }
        const data = await response.json();
        setTotalAgents(data.length); // Number of agents is the length of the array
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDataSequentially = async () => {
      await fetchTotalDisbursements();
      await new Promise((resolve) => setTimeout(resolve, 4000));
      await fetchTotalCollections();
      await new Promise((resolve) => setTimeout(resolve, 4000));
      await fetchTotalMembers();
      await new Promise((resolve) => setTimeout(resolve, 4000));
      await fetchTotalAgents(); // Fetch total agents
    };

    fetchDataSequentially();
  }, []);

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>


      <div className="cards">
      <Link to='/disbursement'>
        <div className="card">
          <h2>Total Disbursements</h2>
         
{
  totalDisbursements === 0 ? (
    <p style={{color: 'red', fontSize: '12px'}}>No disbursements in this branch, no loan approved.</p>
  ) : (
    <p>₹{totalDisbursements.toLocaleString()}</p>
  )
}
        </div>
        </Link>


        <Link to='/collections'>
        <div className="card">
          <h2>Total Collections</h2>
          <p>₹{totalCollections.toLocaleString()}</p>
          <p style={{color: 'green', fontSize: '12px'}}>The total accepted collection</p>
        </div>
        </Link>


        <Link to='/fieldofficers'>
        <div className="card">
          <h2>Total Agents</h2>
          <p>{totalAgents.toLocaleString()}</p>
          <p style={{color: 'green', fontSize: '12px'}}>Number of field-officers</p>
        </div>
        </Link>
        
      </div>
    </div>
  );
};

export default Dashboard;
