import React, { useState, useEffect } from 'react';
import './Collections.css';

const COLLECTIONS_CACHE_KEY = 'collections_cache';
const CACHE_EXPIRATION = 2 * 60 * 1000; // 2 minutes in milliseconds

const Collection = () => {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [collectorId, setCollectorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [collectedFrom, setCollectedFrom] = useState('');
  const [collectedBy, setCollectedBy] = useState('');
  const [error, setError] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [collectorNames, setCollectorNames] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const branchId = localStorage.getItem('srishti_branch_id');
      if (!branchId) {
        setError('No branch ID found in local storage');
        return;
      }

      // Check for cached data
      const cachedData = JSON.parse(localStorage.getItem(COLLECTIONS_CACHE_KEY));
      const now = new Date().getTime();

      if (cachedData && now - cachedData.timestamp < CACHE_EXPIRATION) {
        setCollections(cachedData.collections);
        setFilteredCollections(cachedData.collections);
        calculateTotalAmount(cachedData.collections);
        setCollectorNames(cachedData.collectorNames);
        return;
      }

      try {
        const response = await fetch('https://srishticooperativesociety.com/api/collections');
        if (!response.ok) {
          throw new Error('Failed to fetch collections');
        }
        const data = await response.json();
        const filteredCollections = data.filter(collection => collection.branch_id === parseInt(branchId, 10));
        setCollections(filteredCollections);
        setFilteredCollections(filteredCollections);
        calculateTotalAmount(filteredCollections);

        // Fetch collector names
        const uniqueCollectorIds = [...new Set(filteredCollections.map(col => col.collector_id))];
        const namesMap = {};
        for (let id of uniqueCollectorIds) {
          const nameResponse = await fetch(`https://srishticooperativesociety.com/api/admins/${id}`);
          if (nameResponse.ok) {
            const nameData = await nameResponse.json();
            namesMap[id] = nameData.name;
          }
        }
        setCollectorNames(namesMap);

        // Cache data
        localStorage.setItem(
          COLLECTIONS_CACHE_KEY,
          JSON.stringify({
            collections: filteredCollections,
            collectorNames: namesMap,
            timestamp: now,
          })
        );
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, CACHE_EXPIRATION);
    return () => clearInterval(interval);
  }, []);

  const calculateTotalAmount = (data) => {
    const total = data
      .filter(collection => collection.status === 'accepted')
      .reduce((acc, collection) => acc + parseFloat(collection.collection_amount), 0);
    setTotalAmount(total);
  };

  const updateCollectionStatus = (id, status) => {
    const updatedCollections = collections.map(collection =>
      collection.id === id ? { ...collection, status } : collection
    );

    setCollections(updatedCollections);
    filterCollections(updatedCollections);

    // Update local storage
    const cachedData = JSON.parse(localStorage.getItem(COLLECTIONS_CACHE_KEY)) || {};
    const updatedCachedCollections = cachedData.collections.map(collection =>
      collection.id === id ? { ...collection, status } : collection
    );
    localStorage.setItem(
      COLLECTIONS_CACHE_KEY,
      JSON.stringify({
        ...cachedData,
        collections: updatedCachedCollections,
      })
    );
  };

  const filterCollections = (collectionsToFilter = collections) => {
    let filtered = collectionsToFilter;

    if (collectorId !== '') {
      filtered = filtered.filter(collection => collection.collector_id === parseInt(collectorId, 10));
    }

    if (collectedFrom) {
      filtered = filtered.filter(collection =>
        collection.collected_from.toLowerCase().includes(collectedFrom.toLowerCase())
      );
    }

    if (collectedBy) {
      filtered = filtered.filter(collection =>
        collectorNames[collection.collector_id]?.toLowerCase().includes(collectedBy.toLowerCase())
      );
    }

    if (selectedDate) {
      filtered = filtered.filter(collection =>
        new Date(collection.collection_date).toDateString() === new Date(selectedDate).toDateString()
      );
    }

    if (startDate && endDate) {
      filtered = filtered.filter(collection => {
        const collectionDate = new Date(collection.collection_date);
        return collectionDate >= new Date(startDate) && collectionDate <= new Date(endDate);
      });
    }

    setFilteredCollections(filtered);
    calculateTotalAmount(filtered);
  };

  const handleFilterChange = (setter) => (e) => {
    setter(e.target.value);
    filterCollections();
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  return (
    <div className="collection-container">
      <h1>Collections</h1>
      {error && <p className="error-message">{error}</p>}
      <h3>Total Collections Amount (Accepted by Branch): ₹{totalAmount.toFixed(2)}</h3>

      <input
        type="text"
        className="filter"
        placeholder="Filter by Collected From"
        value={collectedFrom}
        onChange={handleFilterChange(setCollectedFrom)}
      />
      <input
        type="text"
        className="filter"
        placeholder="Filter by Collected By"
        value={collectedBy}
        onChange={handleFilterChange(setCollectedBy)}
      />
      <label>Start Date</label>
      <input
        type="date"
        className="filter"
        value={startDate}
        onChange={handleFilterChange(setStartDate)}
      />
      <label>End Date</label>
      <input
        type="date"
        className="filter"
        value={endDate}
        onChange={handleFilterChange(setEndDate)}
      />
      <label>Select Date</label>
      <input
        type="date"
        className="filter"
        value={selectedDate}
        onChange={handleFilterChange(setSelectedDate)}
      />
      <table className="collections-table">
        <thead>
          <tr>
            <th>Loan ID</th>
            <th>Collected From</th>
            <th>Collected By</th>
            <th>Collection Amount</th>
            <th>Collection Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCollections.map(collection => (
            <tr key={collection.id}>
              <td>{collection.loan_id}</td>
              <td>{collection.collected_from}</td>
              <td>{collectorNames[collection.collector_id] || 'Loading...'}</td>
              <td>₹{collection.collection_amount}</td>
              <td>{formatDate(collection.collection_date)}</td>
              <td>{collection.status}</td>
              <td>
                <button className="accept-button" onClick={() => updateCollectionStatus(collection.id, 'accepted')}>Accept</button>
                <button className="reject-button" onClick={() => updateCollectionStatus(collection.id, 'rejected')}>Reject</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="contact-admin">*Once a collection is accepted, it cannot be rejected. Please contact the admin if you think you made a mistake.</p>
    </div>
  );
};

export default Collection;
